import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import indexStyles from "./index.module.css"

const IndexPage = ({ data }) => {
  const {
    html
  } = data.homePageData.edges[0].node
  return (
    <Layout>
      <SEO title="home" />
          <div
            className={indexStyles.home}
            dangerouslySetInnerHTML={{ __html: html }}
          />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`
